
.inquiryItems {
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: row;
}

.itemName {
  display: flex;
  flex: 1;
  height: 40vh;

  flex-direction: row;
}
.inquiryImage {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inquiryText {
  flex: 1;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inquiryTextBold  {
  font-size: 18px;
  font-weight: bold;
  width: 40vw;
}

.itemNumber {
  display: flex;
  flex: 0.5;
  height: 40vh;
  flex-direction: row;
  align-items: center;
}

.itemImage {
  width: 150px;
  height: 150px;
  background-color: #F3F2F2;
  margin: 40px;
}

.inquiryButton {
  height: 30px;
  width: 30px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1C2124;
  border-radius: 50%;
}

.inquiryButton:hover {
  background-color: #303335;
}
.icon {
  width: 16px;
  height: 16px;
}

.number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  width: 40px;
  height: 40px;
  text-align: center;
  border: 1px solid #E5E5E5;
  margin-right: 10px;
  margin-left: 10px;
}

.inquiryForm {
  background-color: #F3F2F2;
  margin: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formTittle {
  font-size: 28px;
  color: #5C5C5C;
  font-weight: bold;
  margin: 30px;
  margin-bottom: 50px;
}

.formContainer {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  font-size: 18px;
  font-style: italic;
  color: #5C5C5C;
}

.formButton {
  font-size: 18px;
  background-color: #4198DB !important;
  color: white !important;
  font-family: Karla;
}

.input {
  height: 38px;
  width: 50vw;
  border: none;
  padding-left: 10px;
  color: #1C2124;
}

@media only screen and (max-width: 800px) {
  .itemImage {
    width: 100px;
    height: 100px;
  }

}
@media only screen and (max-width: 600px) {
  .itemImage {
    width: 100px;
    height: 100px;
  }
  .inquiryTextBold {
    font-size: 15px;
  }
  .inquiryNumber {
    flex: 0.4
  }

  .inquiryItems {
    flex-direction: column;
  }

  .itemNumber {
    justify-content: flex-start;
    margin-left: 20vw;
    padding-left: 10px;
  }

  .inquiryButton {
    margin-top: -40px;
    height: 20px;
    width: 20px;
  }
  .icon {
    height: 10px;
    width: 10px;
  }
  .number {
    margin-top: -40px;
    height: 20px;
    width: 20px;
    padding-top: 0px;
  }

  .formTittle {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .info {
    font-size: 12px;
  }

  .formButton {
    font-size: 12px;
  }

  .input {
    height: 28px;
  }
}

@media only screen and (max-width: 420px) {
  .itemNumber {
    margin-left: 16vw;
  }
}
