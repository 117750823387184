* {
  font-family: 'Karla', sans-serif;
}

.footerContainer {
  width: 100%;
  height: 50vh;
  position: relative;
  bottom: 0px;
  background-image: linear-gradient(86.62deg, #50AAC1 -5.06%, #4EA9DA 48.19%, #4198DB 96.9%);
  margin: 0px;
  display: flex;
  flex-direction: column;
}

.contentSmall {
  flex: 0.5;
  display: flex;
  align-items: center;
  padding-left: 2vw;
  margin-top: 0px;
}

.content {
  flex: 2;
  margin: 1vw;
  display: flex;
  margin-top: 0px;
}

.contentInner {
  flex:1;
  margin: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading {
  font-weight: bold;
  font-size: 22px;
  padding-top: 0.5vh;
  color:white;
}

.subHeading {
  font-weight: 600;
  font-size: 18px;
  color: white;
}

.text {
  font-size: 18px;
  color:white;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 800px) {
  .footerContainer {
    height: 50vh;
  }

  .heading {
    font-size: 20px;
  }

  .text {
    font-size: 16px;
  }

}

@media only screen and (max-width: 550px) {
  .footerContainer {
    height: 400px;
  }

  .heading {
    font-size: 20px;
  }

  .content {
    flex-direction: column;
    font-size: 12px;
  }

  .contentInner {
    padding: auto;
    text-align: justify;
    align-items: center;
  }

 .contentSmall{
   justify-content: center;
   text-align: center;
 }

 .text {
   padding-top: 0px;
   padding-bottom: 0px;

 }

}
