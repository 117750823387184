* {
  font-family: 'Karla', sans-serif;
}

.headerContainer {
  background-image: linear-gradient(66.29deg, #4167AD -5.06%, #4167AD 3.55%, #4EA9DA 48.19%);
  height: 10vh;
  width: 100%;
  display: flex;
  top: 0px;
  justify-content: space-between;
  align-items: center;
}

.divleft {
  margin: 20px;
}

.divright {
  margin: 20px;
  z-index: 100;
}

.logo {
  width:150px;
}
.navSmall {
  display: none;
}

.headerlinks a {
  margin: 20px;
  font-size: 18px;
  color: white;
}

.headerlinks a:hover {
  color: #E1ECF2;
}

.inquirylink a {
  font-size: 16px;
  color: #4EA9DA;
  background-color: white;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-right: 20px;
}

.inquirylink a:hover {
  background-color: #F2F2F2;
}

.responsiveoptions {
  width: 100%;
  height: 30vh;
  display: none;
}

@media only screen and (max-width: 800px) {
  .navFullsize {
    display: none;
  }

  .navSmall {
    display: flex;
    justify-content: center;
    z-index:100 !important;
  }

  .headericon {
    height: 30px;
    width: 30px;
  }

  .menubutton {
    background-color: transparent;
    border: none;
  }

  .inquirylink {
    font-size: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 6px;
    padding-right: 6px;
  }

  .responsiveoptions {
    background-image: linear-gradient(66.29deg, #5EC1DA -5.06%, #4198DB 3.55%, #4EA9DA 48.19%);
    display: flex;
    flex-direction: column;
    z-index: 100;
    position: relative;
  }

  .option {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 20px;
    z-index: 100;
  }

  .option a {
    color: white;
  }

  .option:hover {
    background-color: #4198DB;
  }

  .option a:hover {
    color: #E1ECF2;
  }

}

@media only screen and (max-width: 400px) {

  .inquirylink a {
    height: 18px;
    font-size: 12px;
  }

  .divright {
    margin: 10px;
  }


}
