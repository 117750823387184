.productContent {
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 80px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}
.docLibrary {
	margin: 10px;
	border: 2px solid #4198DB;
	color: #4198DB;
	font-size: 16px;
	padding: 10px;
	position: absolute;
	top: calc(60px + 10vh);
	left : 210px;
}

.docLibrary a {
		color: #4198DB;
}

.productContent .docLibraryButton {
    all: unset;
    position: relative;
    display: flex;
    align-items: center;
    width: 30vw;
}

.docLibraryTitle {
  font-size: 20px;
  font-weight: bold;
  margin: 30px 10px 30px 10px;
  width: 100%;
  text-align: center;
}

.productContent .sectionSmallContent {
  margin: 15vh;
  margin-top: -30px;
}

.productContent .divcolumn {
  margin: 30px
}

.productContent .divcolumn .logoImage {
  margin: 20px;
}

@media only screen and (max-width: 800px) {
 .productContent {
   margin-top: 100px;
 }
}
@media only screen and (max-width: 600px) {
 .productContent {
   margin-top: 140px;
 }
 .docLibrary {
   top: calc(60px + 20vh);
   left: 10px;
 }

 .productContent .sectionSmallContent {
   margin-top: -60px;
 }

 .productContent .divcolumn {
   margin: 20px;
   text-align: left;
 }

 .productContent .divcolumn .logoImage {
   width: 200px;
   margin: 10px;
 }

 .productContent .docLibraryButton {
     width: 60vw;
 }
}
