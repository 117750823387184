*{
	margin: 0;
	padding: 0;
	outline: none;
}

:active, :focus {
   outline: none;
}

.section1 {
	background-image:url("../images/background.png");
	height: 90vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.title {
	font-family: 'Rubik', sans-serif;
	color: white;
	font-weight: bold;
	font-size: 64px;
}

.subTitle {
	font-size: 24px;
	color: white;
	font-style: italic;
	text-align: center;
	padding-left: 1vw;
	padding-right: 1vw;
}

.CTA {
	text-decoration: none;
	border: 3px solid white;
	color: white;
	font-style: 'Karla';
	font-size: 16px;
	padding: 10px 20px 10px 20px;
	margin-top: 20px;
	font-weight: bold;

}

.CTA:hover{
	text-decoration: none;
	color: white;
}

.CTAdark {
	text-decoration: none;
	border: 3px solid #4198DB;
	color: #4198DB;
	font-style: 'Karla';
	padding: 10px;
	margin-top: 20px;
	text-align: center;

}
.CTAdark:hover{
	text-decoration: none;
	color: #4198DB;
	font-size: 20px;

}

.sectionLarge {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.sectionHeader {
	display: flex;
	flex: 0.5;
	margin-top: 20px;
}

.sectionSmall {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.sectionSmallHeader {
	display: flex;
	flex: 0.3;
}
.sectionSmallContent {
	display: flex;
	flex: 2.5;
}

.sectionContent {
	display: flex;
	flex: 2.5;
}

.headerSection {
	flex: 1;
	display: flex;
	align-items: center;
}

.headerSectionMiddle {
	flex: 0.8;
	display: flex;
	align-items: center;
	justify-content: center;
}

.line {
  border: 1px solid #958D8D;
	width: 100%;
}

.sectionTitle {
	font-family: 'Rubik', sans-serif;
	font-size: 24px;
	color:  #1C2124;
}

.featureBox {
	flex: 1;
	margin: 10px;
	display: flex;
	flex-direction: column;
}

.textBox {
	flex: 1;
	background-color: white;
	margin: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
	text-align: justify;
}

.darkText {
	font-size: 18px;
	color: #1C2124;
	font-style: italic;
	line-height: 1.5;
}

.sectionSmallContent .darkText {
	margin-top: -10vh;
}

.darkHeading {
	font-size: 18px;
	color: #1C2124;
	font-weight: bold;
}

.textBoxTilt {
	flex: 1;
	background-color: white;
	margin: 20px;
	transform: rotate(-4deg);
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
	text-align: justify;
	background-color: #B2CFE6;
}

.imageBox {
	flex:1;
	background-color: white;
	margin: 10px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.featureImage {
	width: 200px;
}

.divrow {
	display: flex;
	flex: 1;
	margin: 10px;
	margin-bottom: 0px;
	margin-top: 0px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	text-align: justify;
}

.divcolumn {
	flex: 1;
	margin: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.subContext {
	font-style: italic;
	font-weight: bold;
	color: #84878a;
}

.logoImage {
	width:180px;
}

.aboutImg {
	width:85%;
	height: 50vh;
}


@media only screen and (max-width: 800px) {
	.title {
		font-size: 48px;
	}

	.subTitle {
		font-size: 20px;
	}

	.sectionTitle {
		font-size: 20px;
	}

	.CTA {
		font-size: 18px;
	}

	.CTAdark {
		font-size: 18px;
	}

	.CTAdark:hover {
		font-size: 20px;
	}

	.headerSectionMiddle {
		flex: 1.2;
	}

	.darkText {
		font-size: 16px;
	}

	.darkHeading {
		font-size: 16px;
	}

	.featureImage {
		width: 200px;
	}

	.sectionContent {
		flex-direction: column;
	}
	.sectionLarge {
		height:200vh;
	}

	.sectionHeader {
		flex: 0.1;
	}

	.logoImage {
		width:180px;
	}

	.aboutImg {
		height: 25vh;
	}

	.CTAdark {
		width: 50%;
	}

	.columnView {
		flex-direction: column-reverse;
	}

}

@media only screen and (max-width: 600px) {
	.title {
		font-size: 34px;
	}

	.subTitle {
		font-size: 18px;
	}

	.sectionTitle {
		font-size: 18px;
	}

	.CTA {
		font-size: 16px;
	}

	.CTAdark {
		font-size: 16px;
		width: 50%;
	}

	.CTAdark:hover {
		font-size: 18px;
	}

	.darkText {
		line-height: 1;
	}

	.headerSectionMiddle {
		flex: 2;
	}

	.featureImage {
			width: 200px;
	}

	.sectionSmallContent {
		flex-direction: column-reverse;
	}

	.logoImage {
		width:150px;
	}

	.sectionSmall {
		height: 120vh;
	}

	.aboutImg {
		width: 100%;
		height: 30vh;
	}
}
