.cardBoxCat {
  height: 300px;
  background-color: #f1f1f1;
  width: 20vw;
  margin: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

a {
  text-decoration: none;
}

.ImageCat {
  flex:4;
  background-color: white;
  margin: 10px;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.cardContentCat {
  flex: 1;
  background-color: white;
  margin:10px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ImageCat .categoryImage {
  height: 100%;
  width: 100%;
}

.cardContentCat .text {
  font-size: 18px;
  color: #1C2124;
  font-weight: bold;
  text-align: center;
}

.cardContentCat a:hover {
  text-decoration: none;
}


@media only screen and (max-width: 1000px) {
  .cardBoxCat {
    width: 32vw;
    height: 350px;
  }
}

@media only screen and (max-width: 600px) {
  .cardBoxCat {
    width: 50vw;
  }
}

@media only screen and (max-width: 400px) {
  .cardBoxCat {
    width: 80vw;
  }
}
