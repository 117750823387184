@import url(https://fonts.googleapis.com/css?family=Karla);
@import url(https://fonts.googleapis.com/css?family=Rubik);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*{
	margin: 0;
	padding: 0;
	outline: none;
}

:active, :focus {
   outline: none;
}

.section1 {
	background-image:url(/static/media/background.f749874f.png);
	height: 90vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.title {
	font-family: 'Rubik', sans-serif;
	color: white;
	font-weight: bold;
	font-size: 64px;
}

.subTitle {
	font-size: 24px;
	color: white;
	font-style: italic;
	text-align: center;
	padding-left: 1vw;
	padding-right: 1vw;
}

.CTA {
	text-decoration: none;
	border: 3px solid white;
	color: white;
	font-style: 'Karla';
	font-size: 16px;
	padding: 10px 20px 10px 20px;
	margin-top: 20px;
	font-weight: bold;

}

.CTA:hover{
	text-decoration: none;
	color: white;
}

.CTAdark {
	text-decoration: none;
	border: 3px solid #4198DB;
	color: #4198DB;
	font-style: 'Karla';
	padding: 10px;
	margin-top: 20px;
	text-align: center;

}
.CTAdark:hover{
	text-decoration: none;
	color: #4198DB;
	font-size: 20px;

}

.sectionLarge {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.sectionHeader {
	display: flex;
	flex: 0.5 1;
	margin-top: 20px;
}

.sectionSmall {
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.sectionSmallHeader {
	display: flex;
	flex: 0.3 1;
}
.sectionSmallContent {
	display: flex;
	flex: 2.5 1;
}

.sectionContent {
	display: flex;
	flex: 2.5 1;
}

.headerSection {
	flex: 1 1;
	display: flex;
	align-items: center;
}

.headerSectionMiddle {
	flex: 0.8 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.line {
  border: 1px solid #958D8D;
	width: 100%;
}

.sectionTitle {
	font-family: 'Rubik', sans-serif;
	font-size: 24px;
	color:  #1C2124;
}

.featureBox {
	flex: 1 1;
	margin: 10px;
	display: flex;
	flex-direction: column;
}

.textBox {
	flex: 1 1;
	background-color: white;
	margin: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
	text-align: justify;
}

.darkText {
	font-size: 18px;
	color: #1C2124;
	font-style: italic;
	line-height: 1.5;
}

.sectionSmallContent .darkText {
	margin-top: -10vh;
}

.darkHeading {
	font-size: 18px;
	color: #1C2124;
	font-weight: bold;
}

.textBoxTilt {
	flex: 1 1;
	background-color: white;
	margin: 20px;
	-webkit-transform: rotate(-4deg);
	        transform: rotate(-4deg);
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
	text-align: justify;
	background-color: #B2CFE6;
}

.imageBox {
	flex:1 1;
	background-color: white;
	margin: 10px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.featureImage {
	width: 200px;
}

.divrow {
	display: flex;
	flex: 1 1;
	margin: 10px;
	margin-bottom: 0px;
	margin-top: 0px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	text-align: justify;
}

.divcolumn {
	flex: 1 1;
	margin: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.subContext {
	font-style: italic;
	font-weight: bold;
	color: #84878a;
}

.logoImage {
	width:180px;
}

.aboutImg {
	width:85%;
	height: 50vh;
}


@media only screen and (max-width: 800px) {
	.title {
		font-size: 48px;
	}

	.subTitle {
		font-size: 20px;
	}

	.sectionTitle {
		font-size: 20px;
	}

	.CTA {
		font-size: 18px;
	}

	.CTAdark {
		font-size: 18px;
	}

	.CTAdark:hover {
		font-size: 20px;
	}

	.headerSectionMiddle {
		flex: 1.2 1;
	}

	.darkText {
		font-size: 16px;
	}

	.darkHeading {
		font-size: 16px;
	}

	.featureImage {
		width: 200px;
	}

	.sectionContent {
		flex-direction: column;
	}
	.sectionLarge {
		height:200vh;
	}

	.sectionHeader {
		flex: 0.1 1;
	}

	.logoImage {
		width:180px;
	}

	.aboutImg {
		height: 25vh;
	}

	.CTAdark {
		width: 50%;
	}

	.columnView {
		flex-direction: column-reverse;
	}

}

@media only screen and (max-width: 600px) {
	.title {
		font-size: 34px;
	}

	.subTitle {
		font-size: 18px;
	}

	.sectionTitle {
		font-size: 18px;
	}

	.CTA {
		font-size: 16px;
	}

	.CTAdark {
		font-size: 16px;
		width: 50%;
	}

	.CTAdark:hover {
		font-size: 18px;
	}

	.darkText {
		line-height: 1;
	}

	.headerSectionMiddle {
		flex: 2 1;
	}

	.featureImage {
			width: 200px;
	}

	.sectionSmallContent {
		flex-direction: column-reverse;
	}

	.logoImage {
		width:150px;
	}

	.sectionSmall {
		height: 120vh;
	}

	.aboutImg {
		width: 100%;
		height: 30vh;
	}
}


.inquiryItems {
  display: flex;
  height: auto;
  width: 100%;
  flex-direction: row;
}

.itemName {
  display: flex;
  flex: 1 1;
  height: 40vh;

  flex-direction: row;
}
.inquiryImage {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inquiryText {
  flex: 1 1;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inquiryTextBold  {
  font-size: 18px;
  font-weight: bold;
  width: 40vw;
}

.itemNumber {
  display: flex;
  flex: 0.5 1;
  height: 40vh;
  flex-direction: row;
  align-items: center;
}

.itemImage {
  width: 150px;
  height: 150px;
  background-color: #F3F2F2;
  margin: 40px;
}

.inquiryButton {
  height: 30px;
  width: 30px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1C2124;
  border-radius: 50%;
}

.inquiryButton:hover {
  background-color: #303335;
}
.icon {
  width: 16px;
  height: 16px;
}

.number {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  width: 40px;
  height: 40px;
  text-align: center;
  border: 1px solid #E5E5E5;
  margin-right: 10px;
  margin-left: 10px;
}

.inquiryForm {
  background-color: #F3F2F2;
  margin: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formTittle {
  font-size: 28px;
  color: #5C5C5C;
  font-weight: bold;
  margin: 30px;
  margin-bottom: 50px;
}

.formContainer {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info {
  font-size: 18px;
  font-style: italic;
  color: #5C5C5C;
}

.formButton {
  font-size: 18px;
  background-color: #4198DB !important;
  color: white !important;
  font-family: Karla;
}

.input {
  height: 38px;
  width: 50vw;
  border: none;
  padding-left: 10px;
  color: #1C2124;
}

@media only screen and (max-width: 800px) {
  .itemImage {
    width: 100px;
    height: 100px;
  }

}
@media only screen and (max-width: 600px) {
  .itemImage {
    width: 100px;
    height: 100px;
  }
  .inquiryTextBold {
    font-size: 15px;
  }
  .inquiryNumber {
    flex: 0.4 1
  }

  .inquiryItems {
    flex-direction: column;
  }

  .itemNumber {
    justify-content: flex-start;
    margin-left: 20vw;
    padding-left: 10px;
  }

  .inquiryButton {
    margin-top: -40px;
    height: 20px;
    width: 20px;
  }
  .icon {
    height: 10px;
    width: 10px;
  }
  .number {
    margin-top: -40px;
    height: 20px;
    width: 20px;
    padding-top: 0px;
  }

  .formTittle {
    font-size: 16px;
    margin-bottom: 30px;
  }

  .info {
    font-size: 12px;
  }

  .formButton {
    font-size: 12px;
  }

  .input {
    height: 28px;
  }
}

@media only screen and (max-width: 420px) {
  .itemNumber {
    margin-left: 16vw;
  }
}

* {
  font-family: 'Karla', sans-serif;
}

.footerContainer {
  width: 100%;
  height: 50vh;
  position: relative;
  bottom: 0px;
  background-image: -webkit-linear-gradient(3.38deg, #50AAC1 -5.06%, #4EA9DA 48.19%, #4198DB 96.9%);
  background-image: linear-gradient(86.62deg, #50AAC1 -5.06%, #4EA9DA 48.19%, #4198DB 96.9%);
  margin: 0px;
  display: flex;
  flex-direction: column;
}

.contentSmall {
  flex: 0.5 1;
  display: flex;
  align-items: center;
  padding-left: 2vw;
  margin-top: 0px;
}

.content {
  flex: 2 1;
  margin: 1vw;
  display: flex;
  margin-top: 0px;
}

.contentInner {
  flex:1 1;
  margin: 1vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.heading {
  font-weight: bold;
  font-size: 22px;
  padding-top: 0.5vh;
  color:white;
}

.subHeading {
  font-weight: 600;
  font-size: 18px;
  color: white;
}

.text {
  font-size: 18px;
  color:white;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 800px) {
  .footerContainer {
    height: 50vh;
  }

  .heading {
    font-size: 20px;
  }

  .text {
    font-size: 16px;
  }

}

@media only screen and (max-width: 550px) {
  .footerContainer {
    height: 400px;
  }

  .heading {
    font-size: 20px;
  }

  .content {
    flex-direction: column;
    font-size: 12px;
  }

  .contentInner {
    padding: auto;
    text-align: justify;
    align-items: center;
  }

 .contentSmall{
   justify-content: center;
   text-align: center;
 }

 .text {
   padding-top: 0px;
   padding-bottom: 0px;

 }

}

* {
  font-family: 'Karla', sans-serif;
}

.headerContainer {
  background-image: -webkit-linear-gradient(23.71deg, #4167AD -5.06%, #4167AD 3.55%, #4EA9DA 48.19%);
  background-image: linear-gradient(66.29deg, #4167AD -5.06%, #4167AD 3.55%, #4EA9DA 48.19%);
  height: 10vh;
  width: 100%;
  display: flex;
  top: 0px;
  justify-content: space-between;
  align-items: center;
}

.divleft {
  margin: 20px;
}

.divright {
  margin: 20px;
  z-index: 100;
}

.logo {
  width:150px;
}
.navSmall {
  display: none;
}

.headerlinks a {
  margin: 20px;
  font-size: 18px;
  color: white;
}

.headerlinks a:hover {
  color: #E1ECF2;
}

.inquirylink a {
  font-size: 16px;
  color: #4EA9DA;
  background-color: white;
  border-radius: 3px;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-right: 20px;
}

.inquirylink a:hover {
  background-color: #F2F2F2;
}

.responsiveoptions {
  width: 100%;
  height: 30vh;
  display: none;
}

@media only screen and (max-width: 800px) {
  .navFullsize {
    display: none;
  }

  .navSmall {
    display: flex;
    justify-content: center;
    z-index:100 !important;
  }

  .headericon {
    height: 30px;
    width: 30px;
  }

  .menubutton {
    background-color: transparent;
    border: none;
  }

  .inquirylink {
    font-size: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 6px;
    padding-right: 6px;
  }

  .responsiveoptions {
    background-image: -webkit-linear-gradient(23.71deg, #5EC1DA -5.06%, #4198DB 3.55%, #4EA9DA 48.19%);
    background-image: linear-gradient(66.29deg, #5EC1DA -5.06%, #4198DB 3.55%, #4EA9DA 48.19%);
    display: flex;
    flex-direction: column;
    z-index: 100;
    position: relative;
  }

  .option {
    flex: 1 1;
    display: flex;
    align-items: center;
    padding-left: 20px;
    z-index: 100;
  }

  .option a {
    color: white;
  }

  .option:hover {
    background-color: #4198DB;
  }

  .option a:hover {
    color: #E1ECF2;
  }

}

@media only screen and (max-width: 400px) {

  .inquirylink a {
    height: 18px;
    font-size: 12px;
  }

  .divright {
    margin: 10px;
  }


}

.productContent button {
  all: unset;
  margin: 10px;
  padding: 10px;
  top: calc(60px + 10vh) ;
  background-color: white;
  border: 2px solid #4198DB;
  position: absolute;
  z-index: 1 !important;
  color: #4198DB;
  left: 10px;
}

.sidebarTitle {
  padding: 20px;
  text-align: center;
}

.sidebarText {
  padding: 20px;
  text-align: center;
}
.sidebarText:hover {
  background-color: #F3F2F2;
}

.sidebarText a {
  color: #1C2124;
}

.sidebarText a:hover {
  color: #4198DB;
  text-decoration: none;
}

@media only screen and (max-width: 400px) {
 .sidebarTitle {
   padding: 10px;
   font-size: 16px;
 }

 .sidebarText {
   padding: 10px;
   font-size: 16px;
 }
 .productContent button{
   font-size: 16px;
 }
}

@media only screen and (max-width: 800px) {

 .productContent button{
   font-size: 16px;
 }

 .sidebar {
   width: 40%;
 }
}

.cardBoxCat {
  height: 300px;
  background-color: #f1f1f1;
  width: 20vw;
  margin: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

a {
  text-decoration: none;
}

.ImageCat {
  flex:4 1;
  background-color: white;
  margin: 10px;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.cardContentCat {
  flex: 1 1;
  background-color: white;
  margin:10px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ImageCat .categoryImage {
  height: 100%;
  width: 100%;
}

.cardContentCat .text {
  font-size: 18px;
  color: #1C2124;
  font-weight: bold;
  text-align: center;
}

.cardContentCat a:hover {
  text-decoration: none;
}


@media only screen and (max-width: 1000px) {
  .cardBoxCat {
    width: 32vw;
    height: 350px;
  }
}

@media only screen and (max-width: 600px) {
  .cardBoxCat {
    width: 50vw;
  }
}

@media only screen and (max-width: 400px) {
  .cardBoxCat {
    width: 80vw;
  }
}

.productContent {
  display: flex;
  width: 100%;
  height: auto;
  margin-top: 80px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}
.docLibrary {
	margin: 10px;
	border: 2px solid #4198DB;
	color: #4198DB;
	font-size: 16px;
	padding: 10px;
	position: absolute;
	top: calc(60px + 10vh);
	left : 210px;
}

.docLibrary a {
		color: #4198DB;
}

.productContent .docLibraryButton {
    all: unset;
    position: relative;
    display: flex;
    align-items: center;
    width: 30vw;
}

.docLibraryTitle {
  font-size: 20px;
  font-weight: bold;
  margin: 30px 10px 30px 10px;
  width: 100%;
  text-align: center;
}

.productContent .sectionSmallContent {
  margin: 15vh;
  margin-top: -30px;
}

.productContent .divcolumn {
  margin: 30px
}

.productContent .divcolumn .logoImage {
  margin: 20px;
}

@media only screen and (max-width: 800px) {
 .productContent {
   margin-top: 100px;
 }
}
@media only screen and (max-width: 600px) {
 .productContent {
   margin-top: 140px;
 }
 .docLibrary {
   top: calc(60px + 20vh);
   left: 10px;
 }

 .productContent .sectionSmallContent {
   margin-top: -60px;
 }

 .productContent .divcolumn {
   margin: 20px;
   text-align: left;
 }

 .productContent .divcolumn .logoImage {
   width: 200px;
   margin: 10px;
 }

 .productContent .docLibraryButton {
     width: 60vw;
 }
}

.cardBox {
  height: 400px;
  background-color: #f1f1f1;
  width: 20vw;
  margin: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.cardImage {
  flex:1.5 1;
  background-color: white;
  margin: 10px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardContent {
  flex: 1 1;
  background-color: white;
  margin:10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardImage .image {
  width: 100%;
  height: 100%;
}

.cardContent .text {
  font-size: 16px;
  color: #272E32;
  text-align: center;
  font-weight: bold;
}

.cardContent a:hover {
  text-decoration: none;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.CTAtextbutton {
  text-align: center;
  padding: 5px;
  color: #4198DB;
  font-weight: bold;
}

.CTAtextbutton:hover{
  color: #5FA5DB;
}

.CTAtext {
  font-size: 16px;
  color: #4198DB;
}

.CTAtext:hover {
  color: #5FA5DB;
}

.modal-open {
  padding-right: 0!important;

}

.modal-dialog {
  position: fixed;
  top: 0px;
  max-height: 90vh;
  width: 100%;
  background-color: white;
  padding: 20px;
  padding-left: 10px;
  overflow-y: auto;
  -webkit-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}

.modal-title {
  font-size: 18px;
  text-align: center;
}

.close {
  position: absolute;
  top: 5px;
  z-index: 10000;
  left: 80vw;
  background-color: white;
  border: none;
  font-weight: bold;
}

.close > span {
  font-size: 22px;
}

.addToList {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modalLightText {
  color: #686464;
}

.ModalImage {
  height: 200px;
  width: 200px;
}

.imageDiv {
  background-color: white;
  margin-top: 10px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalSubTitle {
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-top: 10px;
}

.modal.fade.show {
  width: 100vw;
  height: 100vh;
  background-color: rgba(243, 242, 242, 0.90);
  z-index: 10;
  position: fixed;
  top: 0;
}

.modal-dialog {
  top: 10vh;
  max-height: 70vh;
  width: 80vw;
  left:10vw;
  padding: 30px;
  z-index:100;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-name: slidein;
          animation-name: slidein;

}
@-webkit-keyframes slidein {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}
@keyframes slidein {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}

.itemNumber.modal {
  height: 10vh;
}
.feautureList {
  padding-left: 10px;
}

.feautureList li {
  margin-top: 10px;
  margin-bottom: 20px;
  padding-right: 15px;
}

.modal .inquiryButton {
  margin-top: 0px;
}

.modal .number {
  margin-top: 0px;
}

.btn  {
  all: unset;
  margin: 20px;
  padding: 5px;
  background-color: white;
  border: 2px solid #4198DB;
  color: #4198DB;
  font-weight: bold;
  font-size: 14px;
}

.modal .itemNumber {
  justify-content: center;
  padding-left: 0px;
  margin-left: 0px;
}

.downloadButton {
  display: inline;
}
@media only screen and (max-width: 900px) {
  .cardBox {
    width: 30vw;
  }
  .buttons {
    flex-direction: column;
  }

  .cardImage .image {
    width: 100%;
    height: 150px;
  }

  .modal-dialog {
    left: 5vw;
  }


}

@media only screen and (max-width: 500px) {
  .cardBox {
    width: 60vw;
    height: 50vh;
  }

  .buttons {
    flex-direction: column;
  }

  .feautureList li {
    font-size: 15px;
  }

  .btn {
    padding: 5px;
    margin: 10px;
    font-size: 15px;
  }

  .close {
    left: 78vw;
  }

  .modal-dialog {
    left: 5vw;
  }
}

