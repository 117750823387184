.cardBox {
  height: 400px;
  background-color: #f1f1f1;
  width: 20vw;
  margin: 10px;
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.cardImage {
  flex:1.5;
  background-color: white;
  margin: 10px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardContent {
  flex: 1;
  background-color: white;
  margin:10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.cardImage .image {
  width: 100%;
  height: 100%;
}

.cardContent .text {
  font-size: 16px;
  color: #272E32;
  text-align: center;
  font-weight: bold;
}

.cardContent a:hover {
  text-decoration: none;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.CTAtextbutton {
  text-align: center;
  padding: 5px;
  color: #4198DB;
  font-weight: bold;
}

.CTAtextbutton:hover{
  color: #5FA5DB;
}

.CTAtext {
  font-size: 16px;
  color: #4198DB;
}

.CTAtext:hover {
  color: #5FA5DB;
}

.modal-open {
  padding-right: 0!important;

}

.modal-dialog {
  position: fixed;
  top: 0px;
  max-height: 90vh;
  width: 100%;
  background-color: white;
  padding: 20px;
  padding-left: 10px;
  overflow-y: auto;
  transition-timing-function: ease-in;
  transition-delay: 1s;
}

.modal-title {
  font-size: 18px;
  text-align: center;
}

.close {
  position: absolute;
  top: 5px;
  z-index: 10000;
  left: 80vw;
  background-color: white;
  border: none;
  font-weight: bold;
}

.close > span {
  font-size: 22px;
}

.addToList {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.modalLightText {
  color: #686464;
}

.ModalImage {
  height: 200px;
  width: 200px;
}

.imageDiv {
  background-color: white;
  margin-top: 10px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalSubTitle {
  font-size: 16px;
  font-weight: bold;
  display: block;
  margin-top: 10px;
}

.modal.fade.show {
  width: 100vw;
  height: 100vh;
  background-color: rgba(243, 242, 242, 0.90);
  z-index: 10;
  position: fixed;
  top: 0;
}

.modal-dialog {
  top: 10vh;
  max-height: 70vh;
  width: 80vw;
  left:10vw;
  padding: 30px;
  z-index:100;
  animation-duration: 1s;
  animation-name: slidein;

}
@keyframes slidein {
  from {
    opacity: 0;
  }

  to {
    opacity: 100;
  }
}

.itemNumber.modal {
  height: 10vh;
}
.feautureList {
  padding-left: 10px;
}

.feautureList li {
  margin-top: 10px;
  margin-bottom: 20px;
  padding-right: 15px;
}

.modal .inquiryButton {
  margin-top: 0px;
}

.modal .number {
  margin-top: 0px;
}

.btn  {
  all: unset;
  margin: 20px;
  padding: 5px;
  background-color: white;
  border: 2px solid #4198DB;
  color: #4198DB;
  font-weight: bold;
  font-size: 14px;
}

.modal .itemNumber {
  justify-content: center;
  padding-left: 0px;
  margin-left: 0px;
}

.downloadButton {
  display: inline;
}
@media only screen and (max-width: 900px) {
  .cardBox {
    width: 30vw;
  }
  .buttons {
    flex-direction: column;
  }

  .cardImage .image {
    width: 100%;
    height: 150px;
  }

  .modal-dialog {
    left: 5vw;
  }


}

@media only screen and (max-width: 500px) {
  .cardBox {
    width: 60vw;
    height: 50vh;
  }

  .buttons {
    flex-direction: column;
  }

  .feautureList li {
    font-size: 15px;
  }

  .btn {
    padding: 5px;
    margin: 10px;
    font-size: 15px;
  }

  .close {
    left: 78vw;
  }

  .modal-dialog {
    left: 5vw;
  }
}
