.productContent button {
  all: unset;
  margin: 10px;
  padding: 10px;
  top: calc(60px + 10vh) ;
  background-color: white;
  border: 2px solid #4198DB;
  position: absolute;
  z-index: 1 !important;
  color: #4198DB;
  left: 10px;
}

.sidebarTitle {
  padding: 20px;
  text-align: center;
}

.sidebarText {
  padding: 20px;
  text-align: center;
}
.sidebarText:hover {
  background-color: #F3F2F2;
}

.sidebarText a {
  color: #1C2124;
}

.sidebarText a:hover {
  color: #4198DB;
  text-decoration: none;
}

@media only screen and (max-width: 400px) {
 .sidebarTitle {
   padding: 10px;
   font-size: 16px;
 }

 .sidebarText {
   padding: 10px;
   font-size: 16px;
 }
 .productContent button{
   font-size: 16px;
 }
}

@media only screen and (max-width: 800px) {

 .productContent button{
   font-size: 16px;
 }

 .sidebar {
   width: 40%;
 }
}
